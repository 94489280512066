const Bompul = () => (
  <iframe
    title="봄풀"
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      border: "none",
    }}
    src="https://ig100-0029-tunnel.sangsang.dev"
    name="Bompul"
    id="Bompul"
  >
    이 브라우저는 iframe을 지원하지 않습니다
  </iframe>
);

const SongdoHighSchool = () => (
  <iframe
    title="인천 송도고등학교"
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      border: "none",
    }}
    src="https://ig100-0031-tunnel.sangsang.dev"
    name="SongdoHighSchool"
    id="SongdoHighSchool"
  >
    이 브라우저는 iframe을 지원하지 않습니다
  </iframe>
);


const Nbn = () => (
  <iframe
    title="남원 바이오 산업 연구원"
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      border: "none",
    }}
    src="https://ig100-0032-tunnel.sangsang.dev"
    name="Nbn"
    id="Nbn"
  >
    이 브라우저는 iframe을 지원하지 않습니다
  </iframe>
);



const Seongju = () => (
  <iframe
    title="성주 바이루트"
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      border: "none",
    }}
    src="https://ig100-0033-tunnel.sangsang.dev"
    name="Seongju"
    id="Seongju"
  >
    이 브라우저는 iframe을 지원하지 않습니다
  </iframe>
);

export { Bompul, SongdoHighSchool , Nbn, Seongju};
