import "./App.css";
import { Bompul, Nbn, Seongju, SongdoHighSchool } from "./Iframe";

function App() {
  const { host } = window.location;
  const isBompul = host === "bompul.sangsang.farm";
  const isSongdoHighSchool = host === "songdo-high.sangsang.farm";
  const isNbn = host === "nbn.sangsang.farm";
  const isSeongju = host === "seongju.sangsang.farm";

  return (
    <>
      {isBompul && <Bompul />}
      {isSongdoHighSchool && <SongdoHighSchool />}
      {isNbn && <Nbn />}
      {isSeongju && <Seongju />}
    </>
  );
}

export default App;
